.template {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow-y: auto;
  inset: 0px 0px 0px 0px;
  /* top:50%; */
  position: absolute;
  height: fit-content;
}

.logo {
  width: 100px;
  margin: 0rem 0.5rem;
}

.close {
  display: none;
}

@media only screen and (max-width: 1800px) {
  .download {
    /* display: none; */
    background-color: #04364a;
    display: flex;
    z-index: 1000;
    top: 20px;
    right: 100px;
    border: none;
    font-weight: bolder;
    color: #dfe6e9;
    box-shadow: #04364a;
    font-size: 14px;
    cursor: pointer;
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid #04364a;
    position: fixed;
  }
}

@media only screen and (min-width: 1800px) {
  .download {
    position: fixed;
    background-color: #04364a;
    display: flex;
    z-index: 1000;
    top: 29px;
    right: 200px;
    border: none;
    font-weight: bolder;
    color: #dfe6e9;
    box-shadow: #04364a;
    font-size: 14px;
    cursor: pointer;
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid #04364a;
  }
  .logo {
    width: 150px;
    margin: 0rem 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 100px;
    margin: 0rem 1rem;
  }
  .template {
    overflow-y: auto;
    overflow-x: auto;
    top: 0;
    position: absolute;
  }
  .close {
    display: flex;
    z-index: 1000;
    position: absolute;
    top: 5%;
    right: 30px;
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: rgb(255, 255, 255);
    box-shadow: #04364a;
    font-size: 2.5rem;
    cursor: pointer;
  }
  .download {
    background-color: #dfe6e9;
    display: flex;
    z-index: 1000;
    position: absolute;
    top: 6.5%;
    right: 120px;
    border: none;
    font-weight: bolder;
    color: #04364a;
    box-shadow: #04364a;
    font-size: 18px;
    cursor: pointer;
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid #04364a;
  }
}
.view {
  position: absolute;
  inset: 0px;
  z-index: 3;
  border-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
}

.viewicon {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: rgb(26, 145, 240);
  color: rgb(255, 255, 255);
  transform: scale(0);
  transition: transform 0.1s ease 0s;
}

.templatebuttons {
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  top: -60px;
  left: 57%;
  /* margin-left: -225px; */
  width: 180px;
}

@media screen and (min-width: 1600px) {
  .templatebuttons {
    top: -56px;
    left: 64%;
  }
}

.but {
  display: flex;
}

.downloadbtn {
  background-color: #04364a;
  border: none;
  color: #dafffb;
  font-weight: 600;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s,
    box-shadow 0.1s ease 0s;
  cursor: pointer;
  border-width: 0px;
  user-select: none;
  pointer-events: auto;
  font-size: 14px;
  line-height: 20px;
  padding: 7px 12px;
  /* margin: 0rem 1rem; */
}

.downloadbtn:hover {
  border: 1px solid #04364a;
  background-color: #dafffb;
  color: #04364a;
}

.clrbtn {
  background-color: #04364a;
  border: none;
  color: #dafffb;
  font-weight: 600;
  outline: none;
  white-space: nowrap;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s,
    box-shadow 0.1s ease 0s;
  cursor: pointer;
  border-width: 0px;
  user-select: none;
  pointer-events: auto;
  font-size: 14px;
  padding: 7px 12px;
}

.clrbtn:hover {
  border: 1px solid #04364a;
  background-color: #dafffb;
  color: #04364a;
}

.downloadbtndupe {
  background-color: #04364a;
  border: none;
  color: #dafffb;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s,
    box-shadow 0.1s ease 0s;
  cursor: pointer;
  border-width: 0px;
  user-select: none;
  pointer-events: auto;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 12px;
}
.downloadbtndupe:hover {
  border: 1px solid #04364a;
  background-color: #dafffb;
  color: #04364a;
}
.dwnloadtxt {
  display: flex;
  -webkit-box-align: inherit;
  align-items: inherit;
  -webkit-box-pack: inherit;
  justify-content: inherit;
  gap: inherit;
}

.uploadbtn {
  top: 20%;
  right: 0%;
  font-weight: 600;
  color: rgb(255, 255, 255);
  background-color: #1a9681ab;
  border-radius: 4px;
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s,
    box-shadow 0.1s ease 0s;
  cursor: pointer;
  border-width: 0px;
  user-select: none;
  pointer-events: auto;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 12px;
}
.downloadres {
  width: 300px;
  height: 300px;
  margin-top: 5rem;
}
@media only screen and (max-width: 1200px) {
  .downloadres {
    width: 300px;
    height: 300px;
    margin-top: 3rem;
  }
}
.dwnloadtxt {
  display: flex;
  -webkit-box-align: inherit;
  align-items: inherit;
  -webkit-box-pack: inherit;
  justify-content: inherit;
  gap: inherit;
}
.templateimg {
  width: 15rem;
  height: 20rem;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  cursor: pointer;
  transform: scale(0.97);
  transition: transform 0.5s ease-in-out;
  /* background-color: #04364a; */
  /* border: 10px solid #05264E; */
}
.templateimg:hover {
  transform: scale(1);
  border: 3px solid #3C65F5;
  
}

.activetemplate {
  width: 15rem;
  height: 20rem;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  cursor: not-allowed;
  border: 3px solid #3C65F5;
  transform: scale(1);
}
.activetemplate:hover {
  transform: scale(1);
  border: 4px solid #d41818d0;
}
.fileupload {
  display: inline-block;
  padding: 0.5px;
  cursor: pointer;
  background-color: transparent;
}

.fileupload :hover {
  color: white;
  padding: none;
  margin: none;
}
/* .fileupload input[type="file"] {
  display: none;
} */

.upload {
  color: rgb(255, 255, 255);
  background-color: #1a9681ab;
  border-radius: 4px;
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s,
    box-shadow 0.1s ease 0s;
  cursor: pointer;
  border-width: 0px;
  user-select: none;
  pointer-events: auto;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 12px;
}

.upldimage {
  position: relative;
  border: 4px solid #176b8793;
  width: 120px;
  height: 120px;
}

.fileuploadimg {
  position: relative;
  right: 2rem;
  bottom: 2rem;
  display: inline-block;
  padding: 0.5px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.3rem 0.5rem;
  border-radius: 50%;
}

.fileuploadimg :hover {
  color: rgb(189, 189, 189);
}
.fileuploadimg input[type="file"] {
  display: none;
}

.scorefooter {
  margin-top: 1rem;
  height: 5rem;
}

.probtn {
  background-color: #04364a;
  border: none;
  color: #dafffb;
  font-weight: 600;
  outline: none;
  white-space: nowrap;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.1s ease 0s, color 0.1s ease 0s,
    box-shadow 0.1s ease 0s;
  cursor: pointer;
  border-width: 0px;
  user-select: none;
  pointer-events: auto;
  font-size: 14px;
  padding: 7px 12px;
}

.probtn:hover {
  border: 1px solid #04364a;
  background-color: #dafffb;
  color: #04364a;
}

.templatemain {
  position: relative;
}

.templateheader {
  height: 10vh;
  background-color: white;
  position: fixed;
  width: 60rem;
  z-index: 1000;
}
