.resumescoretemplate {
  background-color: rgba(0, 0, 0, 0.51);
  width: 100%;
  min-height: 100vh;
  top: -1rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreim {
  width: 280px;
}

.suggestionbody {
  padding: 0rem 2rem;
  max-height: 60vh;
}
.suggestionsection {
  max-height: 40vh;
  overflow-y: auto;
}
.scoreBtn {
  width: fit-content;
  margin-top: 1rem;
  background-color: #04364a;
  border: none;
  font-weight: 600;
  color: #f9f9f9;
  box-shadow: #04364a;
  font-size: 16px;
  cursor: pointer;
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid #04364a;
}
.scoreBtn:hover {
  background-color: #074c68;
  border: 1px solid #074c68;
}
.scoreBtn:disabled {
  cursor: not-allowed;
  background-color: #074c68;
  border: 1px solid #074c68;
}
.scoreprogress {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  min-height: 85vh;
}
.textcenter {
  position: relative;
}

.docScanner {
  color: rgba(224, 228, 230, 0.782);
  animation: fadeInOut 2s ease infinite;
  z-index: 1;
}

.doctext {
  color: #04364a;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 2;
  padding: 0rem 2rem;
  text-align: center;
}

.docScannererr {
  font-size: 12rem;
  color: rgba(255, 17, 0, 0.233);
  animation: fadeInOut 2s ease infinite;
  z-index: 1;
}

.doctexterr {
  color: #ff1010;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 2;
  padding: 0rem 2rem;
  text-align: center;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .scorecalc {
    padding: 2rem;
    background-color: white;
    width: 24rem;
  }
  .scoreim {
    width: 250px;
  }
  .docScanner {
    font-size: 10rem;
  }
}

@media only screen and (min-width: 1800px) {
  .circular {
    width: 250px;
  }
}

.circular {
  width: 150px;
}

.suggestions {
  background-color: rgba(240, 248, 255, 0.788);
  border-left: 4px solid #0d6efd;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.resumescore {
  height: 90vh;
}

.upldimage {
  position: relative;
  border: 4px solid #3c64f5b0;
  width: 120px;
  height: 120px;
}

.fileuploadimg {
  position: relative;
  right: 2rem;
  bottom: 2rem;
  display: inline-block;
  padding: 0.5px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.3rem 0.5rem;
  border-radius: 50%;
}

.fileuploadimg :hover {
  color: rgb(189, 189, 189);
}
.fileuploadimg input[type="file"] {
  display: none;
}

/* tagsinput */
.input {
  display: block;
  width: 100%;
  padding: 0.7rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  font-family: inherit;
}

.input:focus {
  outline: 0;
  border: 1px solid #abbce9;
  box-shadow: none;
  background-color: transparent;
}

.tagsinput {
  position: relative;
  margin-bottom: 1rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  white-space: nowrap;
  margin-right: 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 6px 10px;
  font-size: 14px;
  list-style: none;
  border-radius: 5px;
  background: #5891ff;
}

.tagcloseicon {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-left: 10px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  background-color: rgba(188, 207, 250, 0.704);
}

.tagcloseicon:hover {
  background-color: rgba(157, 184, 246, 0.704);
}

/* 404 not found page */
.page_404 {
  padding: 10px;
  background: #fff;

  /* font-family: 'Arvo', serif; */
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 500px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.searchinput {
  border: none;
  color: gray;
  font-size: 13px;
}
.searchinput::placeholder {
  color: gray;
  font-size: 14px;
}
.searchinput:focus {
  background: transparent;
}
.searchinput:hover {
  background: transparent;

}

.options {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  top: 50px;
  padding: 8px 0px;
  font-size: 14px;
}

.search {
  padding: 10px 12px;
  font-size: 14px;
}
.search:hover {
  background-color: #deebff;
}
