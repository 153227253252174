@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.form-control {
  padding: 11px 15px 13px 15px;
  height: 50px;
}
.form-control:focus {
  border: 1px solid #abbce9;
  box-shadow: none;
  background-color: transparent;
}
.form-label {
  font-weight: 500;
  font-size: 14px;
  color: #a0abb8;
}
.form-check-input {
  box-shadow: none !important;
}

.form-check-input:checked {
  box-shadow: none !important;
}

.form-control::placeholder {
  color: #b9bfc7;
}
.was-validated .form-control:invalid,
.form-control.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-select:invalid,
.form-select.is-invalid {
  background-image: none;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  font-size: 16px;
  font-weight: 600;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #3c65f5;
}
.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: #3c65f5;
  color: #3c65f5;
}
.dropdown-menu {
  --bs-dropdown-padding-y: 0rem;
  font-size: 14px;
  --bs-dropdown-min-width: 6rem;
}
.dropdown-item {
  padding: 0.5rem 1rem;
}
.dropdown-menu[data-bs-popper] {
  top: 107%;
  left: 0%;
  margin-top: 0.5rem;
  border: 1px solid #a0abb849;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.modal-footer {
  border-top: none;
}
.modal-header {
  border-bottom: none;
  align-items: start;
}
.modal-dialog {
  display: flex;
  align-items: start;
  justify-content: center;
}

.modal-content {
  border: none;
}

#formresume ::-webkit-scrollbar {
  width: 0;
}

#formresume ::-webkit-scrollbar-track {
  background: transparent;
}

#formresume ::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: default;
}

#formresume {
  scrollbar-width: none;
}

#formresume {
  -ms-overflow-style: none;
}

.ql-toolbar.ql-snow {
  border-radius: 7px 7px 0px 0px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 7px 7px;
}
.ql-container {
  font-family: inherit;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}
:root {
  --swiper-navigation-size: 44px;
  --swiper-theme-color: #007aff;
  --swiper-theme-color: #007aff;
}

/* .custom-pagination {
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.custom-pagination .page-item {
  margin: 0 5px;
}

.custom-pagination .page-link {
  padding: 10px 8px;
  font-weight: 600;
  color: #a0abb8;
  line-height: 26px;
  text-decoration: none;
  position: relative;
  font-size: 18px;
  font-weight: bold;
}

.custom-pagination .custom-page-prev-next .page-link {
  border-radius: 50%;
  border: 1px solid #007bff;
  color: #007bff;
}

.custom-pagination .custom-page-prev-next .page-link:hover {
  background-color: #007bff;
  color: white;
}

.custom-pagination .page-item.active .page-link {
  background-color: gray;
  color: white;
}

.custom-pagination .page-item.disabled .page-link {
  color: #6c757d;
}

.custom-pagination .custom-page-item .page-link {
  background: none;
  color: #007bff;
}

.custom-pagination .custom-page-item .page-link:hover {
  color: #0056b3;
} */
