.heading {
    font-size: 24px;
    font-weight: 700;
}

.jobDetails {
    /* py-3 px-4 rounded-3 d-flex mb-4 shadow-sm border */
    display: flex;
    border-radius: 10px;
    border: 1px solid #cccccc52;
    margin-bottom: 20px;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)
}

.jobDetails:hover {
    transform: translateY(-4px);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    background-color: #f7f9fbb1;
}


.customNavlink {
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    width: 120px;
    height: 32px;
    font-weight: 600;
    border: 1px solid #e7e6e6;
    font-size: 14px;
    padding: 10px;
}

.mdText {
    font-size: 14px;
    color: #b5b4b4;
    font-weight: 700;
}

.smText {
    font-size: 12px;
    color: #4F5E64;


}

.value {
    font-size: 14px;
    color: #b5b4b4;
    font-weight: 700;
    font-weight: 700;
    color: #169d5c;
    background-color: #d0d9f6a8;
    padding: 3px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.dropdownToggle::after {
    display: none !important;
}

.dropdownToggle {
    background-color: #ffff;
    color: #A0ABC3;
    border-color: #A0ABC3;
    font-weight: 800;
    font-size: 16px;
    border-radius: 6px;
}

.dropdownToggle:hover {
    background-color: #ffff;
    color: #A0ABC3;
    border-color: #A0ABC3;
    border-radius: 6px;

}

.dropdownToggle:active {
    background-color: #ffff;
    color: #A0ABC3;
    border-color: #A0ABC3; 
    border-radius: 6px;

}

.dropdownToggle:focus {
    background-color: #ffff;
    color: #A0ABC3;
    border-color: #A0ABC3;

}