/* input */
.form {
  position: relative;
  margin: 0.3rem 0rem;
}
.input {
  border-radius: 5px;
  height: 2.8rem;
  color: #212121;
  font-size: 14px;
  background-color: #4985e01f;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  border-bottom: 1px solid #dddddd63;
}
.inputError {
  border-radius: 5px;
  height: 2.5rem;
  color: #212121;
  font-size: 0.9rem;
  background-color: #d64f4f1e;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  border: 1px solid rgba(247, 22, 22, 0.925);
  transition: 0.3s border;
}
.inputborder {
  position: absolute;
  background: #5891ff;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}
::placeholder {
  color: #656e83;
  font-size: 12px;
}

.inputlabel {
  font-size: 12px;
  color: #656e83;
  margin: 0.2rem 0rem;
  font-weight: 500;
}
.inputlabel span {
  font-size: 16px;
}
input:hover {
  background: #4985e01f;
}

input:focus {
  outline: none;
}
input:focus ~ .inputborder {
  width: 100%;
}

.texteditor {
  height: 180px;
}



/* tagsinput */
.tagsinput {
  position: relative;
  margin-bottom: 1rem;
}

.tags {
  display: flex;
  flex-wrap: wrap; 
}

.tag {
  white-space: nowrap; 
  margin-right: 6px; 
  width: fit-content;
  display: flex;
  align-items: center;
  color: #05264E;
  font-weight: 600;
  padding: 6px 10px;
  font-size: 14px;
  list-style: none;
  border-radius: 5px;
  background: #B1CEFE;
}

.tagcloseicon {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-left: 10px;
  color: #05264E;
  padding: 3px;
  border-radius: 26px;
  cursor: pointer;
  font-weight: 600;
}

/* select */
select option {
  font-size: 12px;
  background-color: #f2f2f5;
  color: #000000;
  padding: 10px;
  border: none;
}

select option:checked {
  background-color: #5891ff;
}

select.input::-ms-expand {
  display: none;
}

select.input:focus{
  outline: none;
}











.floatinglabelcontent {
  position: relative;
  margin: 0.5rem 0rem;
}

.floatinginput,
.floatingselect,
.floatingtextarea {
  border-radius: 3px;
  padding: 12px 16px;
  width: 100%;
  display: block;
  caret-color: rgb(26, 145, 240);
  background-color: rgb(208, 217, 236);
  outline: none;
  border: 0px;
  color: rgb(30, 37, 50);
  transition: color 0.1s ease 0s;
  font-size: 14px;
}
.floatingtextarea {
  resize: none;
}
.floatinginput:focus {
  outline: none;
  border-bottom: 2px solid #176b87;
}

.errorMessage {
  color: red;
  font-size: 12px;
  padding-top: 1rem;
}
.floatinglabel {
  font-size: 14px;
  color: #000000;
  margin: 5px 0px;
  font-weight: 600;
}

/* Remove spinner icon in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove spinner icon in WebKit (Chrome, Safari) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Remove spinner icon in Edge */
input[type="number"] {
  -ms-inner-appearance: none;
}

/* Additional styles for better compatibility */
input[type="number"] {
  appearance: textfield;
}



















.addformicon {
  font-size: 24px;
}

.addformtext {
  font-size: 14px;
  font-weight: 700;
}

.invisibleinput {
  border: 0px;
  height: 2em;
  width: 100%;
  /* font-size: 18px; */
  /* font-weight: 600; */
  background-color: rgb(208, 217, 236);
  margin: 0.8rem 0rem 0rem 0rem;
  padding: 1rem;
  border-radius: 3px;
}

.invisible textarea {
  resize: none;
  border: 0px;
  height: 2rem;
  width: 100%;
}

.invisible select {
  font-size: 12px;
  color: #000000;
}
.floatinglabelcontent input:focus {
  outline: none;
}

.invisible textarea:focus {
  outline: none;
}

.customselect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px 30px;
  background-size: 10px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border: none;
  background-color: #f2f2f5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 12px;
  color: #726d6d;
}

.customselect:focus {
  outline: none;
}
select::-ms-expand {
  display: none;
}


.skilltag {
  color: #726d6d;
  font-weight: 600;
  font-size: 12px;
  padding-right: 1em;
}

.customButtonGroup {
  border: none;
}

.custom {
  background-color: #726d6d;
  color: white;
  border-color: transparent;
  &:hover {
    background-color: #050404;
    border-color: transparent;
  }
}

.customactive {
  background-color: #050404;
  color: white;
  border-color: transparent;
  &:hover {
    background-color: #050404;
    border-color: transparent;
  }
}



.radio {
  align-items: center;
  justify-content: center;
  display: flex;
  background: #f2f2f2;
  padding: 4px;
  border-radius: 3px;
  position: relative;
}

.radio input {
  width: 3rem;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  /* border-radius: 2px; */
  padding: 4px 8px;
  background: #7fffd413;
  color: #176b87ab;
  font-size: 14px;
  transition: all 100ms linear;
}

.radio input:checked {
  background-image: linear-gradient(180deg, #5891ff, #74bbad);
  /* background-image: linear-gradient(180deg, #95d891, #74bbad); */
  color: #000000;
  box-shadow: 0 1px 1px #0000002e;
  text-shadow: 0 1px 0px #79485f7a;
}

.radio input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

