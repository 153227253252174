.containereula {
  margin: 2rem 15rem;
  text-align: left;
}

.eulah1{
    font-weight:600;
    font-size: 2.5rem;
    line-height: 2.5;
}

.eulap{
    font-size: 14px;
    line-height: 1.5;

}
.eulaa{
    color: rgb(3, 3, 255);
}

ul{
    font-size: 14px; 
    line-height: 1.5;

}
.li{
    line-height: 1.5;
 
}

.eualh2{
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 2.5;

}
.hr{
    margin-bottom: 1.5rem;
}