.CompanyProfile {
  max-width: 90%;
  display: table;
  table-layout: fixed;
  margin: auto;
  width: 100%;
  padding: 20px 0px 0px 0px;
  position: relative;
}

.imageCompany {
  position: absolute;
  top: -50px;
  left: 0px;
}

.imageCompany img {
  width: 100%;
  border-radius: 16px;
}

.companyName {
  font-size: 22px;
  font-weight: 600;
}

.eduelement {
  color: #d93025;
  background-color: rgba(217, 48, 37, 0.15);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 0.25rem 1rem;
  text-align: center;
}
.companyLocation {
  font-size: 13px;
  color: #a0abb8;
  display: inline-block;
  padding: 0px 0px 0px 20px;
  line-height: 24px;
}

.editprofile {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  background-color: #3c65f5;
  color: #ffffff;
  padding: 18px 20px;
  border-radius: 4px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
}

.contactUs:hover {
  background-color: #2f5af6;
}

.contactUs span {
  font-size: 16px;
}

.customNavlink {
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 6px;
  transition: all 0.3s;
}

.customNavlink:hover {
  transform: translateY(-3px);
}

.smText {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4f5e64;
  gap: 2px;
}

.mdText {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #4f5e64;
  gap: 28px;
}

.jobCard {
  background-color: #f8faff;
  border: 1px solid #e0e6f7;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.jobCard:hover {
  background-color: #ffffff;
  border-color: #b4c0e0;
  transform: translateY(-4px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.skillBadge {
  background-color: #eff3fc;
  border-radius: 5px;
  display: inline-block;
  font-size: 15px;
  color: #4f5e64;
}

.jobCard:hover .applyNow {
  background-color: #2f5af6;
  color: #fff;
}

.applyNow {
  border: none;
  border-radius: 5px;
  padding: 7px;
  color: #2f5af6;
  background-color: #e2e8f5;
  font-size: 15px;
}

.iconStyles {
  font-size: 20px;
}

.linkContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}

.linkContainer a {
  text-decoration: none;
  color: inherit;
  word-break: break-all;
  max-width: 100%;
  display: block;
}

.boxheadsingle {
  display: inline-block;
  width: 100%;
  padding: 45px 0px;
  position: relative;
}

.boxheadsingle::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 1;
  width: 100px;
  /* background: url(../../../assets/bg-head-left-candidate.svg) no-repeat right
    bottom; */
}

.headingimage img {
  border-radius: 50%;
  height: 150px;
  width: 150px;
}
.profileinfo {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
/* 
.boxheadsingle::after {
  width: 165px;
  z-index: 1000;
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: url(../../../assets/bg-header-right.svg) no-repeat right bottom;
} */
.personalinfo {
  border: 1px solid rgba(6, 18, 36, 0.1);
  padding: 29px 33px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 9px 26px 0px rgba(31, 31, 51, 0.06);
  margin-bottom: 40px;
}

.jobsearchinfo {
  border: 1px solid rgba(6, 18, 36, 0.1);
  border-radius: 10px;
  background-color: rgb(81, 146, 255);
  box-shadow: 0px 9px 26px 0px rgba(31, 31, 51, 0.06);
  margin-bottom: 40px;
  background-image: url(../../../assets/jobsearchsidebar.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 30px 70px 70px 30px;
}
