
.custombutton {
    background-color: #04364A;
    border: none;
    color: #DAFFFB;
    padding: 8px 25px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 600;
    width:100%
  }
  
  .custombutton:disabled {
    cursor: not-allowed;
  }
  .custombutton:hover,
  .custombutton:active,
  .custombutton.active {
    border:1px solid #04364A;
    background-color: #DAFFFB;
    color: #04364A;
  }


  .custombuttonoutline {
    background-color:transparent;
    border:1px solid #04364A;
    color: #04364A;
    padding: 8px 25px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    /* margin-top: 2rem; */

  }
  
  .custombuttonoutline:hover,
  .custombuttonoutline:active,
  .custombuttonoutline.active {
    background-color: #04364A;
    border: none;
    color: #DAFFFB;
  }