.authmain {
  padding-top: 3rem;
}
.loginregcover {
  position: relative;
  border-bottom: 1px solid #dde3e8;
}
.formcover{
  /* background-color: #9db3fa31; */
  padding: 1.5rem 2rem;
  border-radius: 5px;
}
.imgAuth2 {
  max-width: 100%;
}
.sociallogin {
  background-color: white;
  height: 53px;
  border-radius: 4px;
  border: 1px solid #e0e6f6;
  width: 100%;
  line-height: 53px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 14px;
  transition: color 0.3s ease, transform 0.3s ease;
}
.sociallogin:hover {
  color: #3c65f5;
  transform: translateY(-4px);
}
.divider {
  position: relative;
}
.subHeading {
  font-size: 30px;
}
.divider span {
  display: inline-block;
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  z-index: 2;
  font-size: 14px;
}

.divider::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #e0e6f6;
  top: 50%;
  left: 0;
  z-index: 1;
}

.chooseRole {
  position: fixed;
  top: 0%;
  left: 2%;
  width: 100%;
  padding: 20px 0;
}
.logo {
  position: absolute;
}
.hirerside {
  transition: 0.3s ease all;
  cursor: pointer;
  padding:2rem 5rem;
  max-width:650px 
}

.seekerside {
  height: 100vh;
  transition: 0.3s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: #e1edfd; */
  cursor: pointer;
}
.hirerside .hirer {
  height: 250px;
}

.choosebg{
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #F2F6FD;
  background-image: url(../../assets/bg-banner.svg);
  justify-content: center;
  background-size: cover;
  background-repeat: repeat;
}
.seekerside .seeker {
  width: 100%;
  height: 350px;
}

.sidebtn {
  margin-top: 1.2rem;
  color: white;
  border-radius: 4px;
  font-size: 18px;
  padding: 12px 45px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  line-height: inherit;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.col,
.col1 {
  position: relative;
  border-radius: 10px;
  border: 0.88px solid #e0e6f7;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 3rem;
  /* height: 200px; */
  cursor: pointer;
  width: 300px;
  transition: transform 0.3s ease; 
  animation: fadeInUp 1s ease forwards;
}

.col .content,
.col1 .content {
  display: none;
  position: absolute;
  top:70%;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.822);
  animation: slideIn 0.3s ease forwards; 

}

@keyframes slideIn {
  from {
    transform: translateY(100%); 
  }
  to {
    transform: translateY(0); 
  }
}

.col:hover .content,
.col1:hover .content {
  display: block; 
  opacity: 1;

}
.col .content h5,
.col1 .content h5 {
  font-size: 14px;
  background-color: #3c65f5;
  padding: 0.5rem .5rem;
  margin: 1rem;
  color: white;
  border-radius: 5px;
}

.col img {
  width: 100%;
  transform: scale(1.8);
}
.col:hover {
  background-color: #3c64f542;
  transform: translateX(-5px); 

}
.col1 img {
  width: 100%;
  transform: scale(1.3);
}
.col1:hover {
  background-color: #3c64f542;
  transform: translateX(5px); 


}
