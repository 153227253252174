.datewrap {
  border-radius: 8px;
  position: absolute;
  top: 8px;
  right: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  transition: transform 0.14s ease 0s;
  transform-origin: 50% 0px;
  width: 180px;
  height: 180px;
  box-shadow: rgba(15, 56, 113, 0.08) 0px 2px 4px -1px,
    rgba(15, 56, 113, 0.12) 0px 8px 20px -4px,
    rgba(15, 56, 113, 0.32) 0px 0px 1px 0px;
  left: 0px;
}

.yeardate {
  padding: 13px 24px 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.dateicon {
  font-size: 12px;
  user-select: none;
  cursor: pointer;
  color: rgb(130, 139, 162);
  transition: color 0.1s ease 0s;
}

.year {
  font-weight: 500;
  user-select: none;
  padding: 5px 15px;
  font-size: 15px;
  color: rgb(0, 0, 0);
  border-radius: 15px;
  cursor: pointer;
  /* background-color: #5891ff; */
}


.month {
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  color: rgb(30, 37, 50);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 3px;
}

.month:hover {
  background-color: rgba(26, 144, 240, 0.493);
  padding: 5px 3px;
  border-radius: 30px;
  color: white;
}

.activeMonth {
  background-color: #5891ff;
  padding: 5px 3px;
  border-radius: 30px;
  color: white;
}

@media screen and (min-width: 1600px) {
  .datewrap {
    width: 210px;
  }
}

.radio {
  align-items: center;
  justify-content: center;
  display: flex;
  /* background: #f2f2f2; */
  padding: 4px;
  border-radius: 3px;
  /* box-shadow: inset 0 0 0 3px rgba(35, 33, 45, 0.3),
		0 0 0 3px rgba(185, 185, 185, 0.3); */
  position: relative;
}

.radio input {
  /* width: 3rem; */
  /* height: 100%; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  /* border-radius: 2px; */
  /* padding: 4px 8px; */
  /* background: #7fffd413; */
  /* color: #1a9681ab; */
  font-size: 14px;
  transition: all 100ms linear;
}

.radio input:checked {
  background-image: linear-gradient(180deg, #7fffd478, #74bbad);
  color: #000000;
  box-shadow: 0 1px 1px #0000002e;
  text-shadow: 0 1px 0px #79485f7a;
}

.radio input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}