.bgmain {
  background-color: aliceblue;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.heading {
  letter-spacing: 1.2px;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.sub {
  font-size: 16px;
  text-align: justify;
}

@media only screen and (max-width: 1200px) {
  .heading {
    margin-bottom: 1rem;
    margin-top: 6rem;
    font-size: 24px;
  }
  .sub {
    font-size: 14px;
    padding: 0rem 2rem;
    text-align: center;
  }
}
.resumeimagecard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resumeimagecard img {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  height: 20rem;
  width: 15rem;
  transform: scale(0.9);
}

.resumeimagecard:hover img {
  transform: scale(1);
  border: 3px solid #04364ad0;
}

.preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  color: #961a1a;
  font-size: 16px;
}

.previewbutton {
  background-color: #04364a;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  color: #dafffb;
  font-size: 14px;
}

/* .previewbutton:hover,
.previewbutton:active,
.previewbutton.active {
  background-color: #1a9681ab;
  border: none;

} */
.resumeimagecard:hover .preview {
  opacity: 1;
}

.resumetabs {
  text-align: center;
  width: 5rem;
  background-color: #04364a;
  padding: 0.5rem;
  border-radius: 18px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #dafffb;
}
