.sidebar {
  color: #A0ABB8;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  margin-left: 1.1rem;
  margin-top: .5rem;
  border: 1px solid #3c64f58a;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 9vh;
}

.ul {
  list-style-type: none;
  padding: 0; 
  margin: 0; 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ul li {
  min-width:6rem;
  height: 4rem;
  width: 100%; /* Make li elements fill the width of the ul */
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.icon {
  font-size: 1.5rem;
  position: relative;

}
.name {
  font-size: 12px;
}
.navButton {
 
  cursor: pointer;
  color: #05264E;
}
.navButton:hover {
  background-color:#B1CEFE ;
  color:  #05264E;
}

.activeNav {
  background-color: #B1CEFE;
  color: #05264E;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: -90%;
  transform: translateY(-1%);
  background-color: #05264E;
  color:#B1CEFE ;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 999;
}

.icon:hover .tooltip {
  opacity: 1;
}

@media only screen and (min-width: 1800px) {
 
  .ul li {
    min-width:7.8rem;
  }
  
}
