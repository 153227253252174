.heading {
  padding: 0rem 2rem;
}
.heading h5 {
  font-size: 26px;
  font-weight: 700;
  color: #176b87;
}
.chosen{
    font-size: 13px;
    color: #64CCC5
}
.fileupload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin: 1rem 0rem;
}

.fileupload .fileselect {
  display: block;
  border: 2px dashed #dce4ec;
  color: #34495e;
  cursor: pointer;
  /* line-height: 40px; */
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  min-height:15rem;
}

.fileupload .fileselect:hover {
  border-color: #34495e;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.fileupload .fileselect input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.fileupload .fileselect.fileselect-disabled {
  opacity: 0.65;
}

.fileupload .fileselect.fileselect-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.createbutton {
  background-color: #04364A;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  color: #DAFFFB;
  font-size: 14px;
  margin-bottom: 1rem;
}

.createbutton:hover,
.createbutton:active,
.createbutton.active {
  background-color: #1a9681ab;
  border: none;

}