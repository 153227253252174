.resumebuilder {
  /* display: grid; */
  background-color: #faf9ff;
}
.resumebuilderFormContainer {
  /* position: relative; */
  /* margin: 5rem 0rem; */
  background-color: #faf9ff;
  height: 100vh;
}
.header {
  height: 10vh;
}
.formresume {
  /* position: relative; */
  height: 80vh;
  overflow-y: auto;
  padding: 0rem 1.5rem;
}


.bghomepage {
  /* background-image: url(../../assets/score.png); */
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
  height: 100vh;
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  right: 0;
}

.resumebuildertemplate {
  position: relative;
  top: 0px;
  bottom: 0px;
  right: 0px;
  overflow-y: auto;
  min-height: 100vh;
  z-index: 0;
  background: #d6eaf1f1;
  /* background:  #525659 */
}
.closebutton {
  display: none;
}

@media only screen and (max-width: 600px) {
  .resumebuildertemplate {
    position: fixed;
    /* inset:0; */
    /* top: 100px;
    bottom: 0px;
    right: 3px; */
    min-height: 110vh;
    width: 100%;
    background: #04364acb;
    overflow-x: hidden;
  }
  .closebutton {
    display: flex;
    margin-left: 21.5rem;
    margin-top: 1.5rem;
    background-color: transparent;
    border: none;
    font-weight: bolder;
    color: rgb(255, 255, 255);
    box-shadow: #04364a;
    font-size: 20px;
  }
}

.profile {
  font-size: 2.5rem;
  color: #04364a;
  cursor: pointer;
}

.dropDownContent {
  position: absolute;
  top: 8%;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 10rem;
  height: fit-content;
  font-size: 14px;
  font-weight: 700;
  padding: 15px;
  text-align: right;
  border-radius: 10px;
  background-color: #d6eaf1f1;
}
.dropName {
  line-height: 1.5rem;
  cursor: pointer;
  padding: 2px 10px;
  color: #04364a;
}
.dropName:hover {
  background-color: #04364a;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
}
.headermain {
  position: relative;
}
