.bgPostJob {
    background: url(../../../../../assets/postJobBg.svg) no-repeat right 20px bottom;
}


.iconEdu {
    background: url(../../../../../assets/edu.svg) no-repeat left center;
    padding-left: 31px;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #05264E;
}

.TextEditorLabel {
    font-weight: 500;
    font-size: 14px;
    color: #4e4f50;
    padding-left: 6px;

}

@media (max-width: 991.98px) {
    .bgPostJob {
        background: none;
    }
}