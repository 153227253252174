.jobcard {
  border-radius: 8px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  background: #f8faff;
  height: 18rem;
  position: relative;
}
.jobcard:hover {
  border-color: #b4c0e0;
  background-color: #ffffff;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

@media only screen and (max-width: 968px) {
  .jobcard {
    height: auto;
  }
}

.ribbon {
  --f: 1px;
  --r: 15px;
  --t: 10px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 3px 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
}
.cardimggrid {
  padding: 30px 20px 15px 20px;
}

.imagebox img {
  min-width: 60px;
  height: 60px;
  border-radius: 5px;
}

.salprice {
  color: #3c65f5;
  font-weight: bold;
  font-size: 18px;
}
.rightinfo a {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  display: block;
  text-transform: capitalize;
}
.rightinfo span {
  display: inline-block;
  font-size: 12px;
  color: #a0abb8;
}

.cardimggriddet {
  padding: 0px 20px 30px 20px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.cardimggriddet h6 {
  font-size: 16px;
  line-height: 26px;
  color: #05264e;
  text-decoration: none;
}

.jobicons span {
  font-size: 12px;
  color: #a0abb8;
  display: inline-block;
}

.applybtncard {
  background-color: #e0e6f7;
  color: #3c65f5;
  padding: 15px 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  transition: 0.2s;
  cursor: pointer;
}
.applybtncard:hover {
  background-color: #3c65f5;
  color: #e0e6f7;
}

.search {
  padding: 10px 12px;
  font-size: 14px;
}
.search:hover {
  background-color: #deebff;
}
.options {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  top: 50px;
  border-radius: 5px;
  border:1px solid rgba(128, 128, 128, 0.322);
  padding: 8px 0px;
  font-size: 14px;
}
