.compcard {
  border-radius: 8px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  background: #f8faff;
  max-height: 22rem;
  min-height: 20rem;
}
.joblistcard{
  border-radius: 8px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  background: #f8faff;
  height: auto;
}

.compcard:hover {
  border-color: #b4c0e0;
  background-color: #ffffff;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.filterbannerComp {
  padding: 40px 50px;
  background: #f2f6fd;
  border-radius: 16px;
  position: relative;
  max-width: 1411px;
  margin: 0 auto;
}
.cardimggridcomp {
  padding: 30px 20px 15px 20px;
  height: 19rem;
  /* max-height: 20rem; */
}

.cardimggridcomp a {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #05264e;
  text-decoration: none;
}

.cardimggridcomp a:hover {
  color: #3c65f5;
}

.info span {
  font-size: 12px;
  color: #a0abb8;
}
.imageboxcomp img{
  border-radius: 10px;
  min-width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.companybtncard {
  padding: 17px 21px;
  gap: 10px;
  background: #e0e6f7;
  border-radius: 4px;
  color: #05264e;
  font-size: 14px;
  line-height: 22px;
  margin-top: 1.1rem;
  font-weight: 600;
}
.companybtncard:hover {
  color: #3c65f5;
}
