.resumeformheading {
  font-weight: bold;
  font-size: 24px;
  margin-top: 1rem;
}
.resumeformsubheading {
  font-weight: 500;
  font-size: 14px;
  color: #000000c4;
  margin-bottom: 0.5rem;
}
.resumecontentform {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  */
  padding: .5rem 1.3rem ;
  border-radius: 10px;
  background-color: white;
  /* border-top: 3px solid #04364a; */
}
.radio {
  font-size: 14px;
  background-color: rgb(208, 217, 236);
  padding: 3px 16px;
  border-radius: 5px;
}

.gender {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1px;
}
.resumeformheadingsub {
  font-weight: bold;
  font-size: 18px;
  color: #828285;
}

.resumesubform {
  background-color: #fdfdfd;
  margin: 2rem 0rem;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #04364a;
}

.trash {
  color: #828285;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.trash:hover {
  color: #ff5151;
  transform: scale(1.08);
}

/* .summary textarea {
  resize: none;
  border: 3px solid #f2f2f5;
  height: 10rem;
  width: 100%;
  font-size: 14px;
  padding: 1rem;
} */

.addicon {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #05264e;
  padding: .25rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  width: fit-content;
  margin: 2rem 0rem;
  font-size: 16px;
  font-weight: 700;
}
.addformtext {
  font-size: 10px;
}
.addicon:hover {
  background-color: transparent;
  color: #05264E;
  border: 1px solid #05264E;
}
.toggle {
  cursor: pointer;
}
.texteditor {
  height: 210px;
  width: 500px;
}
@media only screen and (min-width: 1700px) {
  .texteditor {
    height: 210px;
    width: 660px;
  }
}
@media only screen and (max-width: 600px) {
  .addicon {
    display: flex;
    align-items: center;
    background-color: #04364a;
    color: #dafffb;
    padding: 0.4rem;
    cursor: pointer;
    border-radius: 10px;
    width: 35%;
    font-size: 14px;
  }
  .texteditor {
    height: 300px;
    width: 300px;
  }
}
