.filterbanner {
  padding: 40px 20px 60px 20px;
  background: #f2f6fd;
  border-radius: 16px;
  position: relative;
  max-width: 1411px;
  margin: 0 auto;
}

.filterbanner::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: -1px;
  height: 170px;
  width: 218px;
  background-size: contain;
  display: inline;
  top: auto;
  right: auto;
  background: #f2f6fd url(../../../assets/left-job-head.svg) no-repeat left
    bottom;
  background-size: contain;
}
.filterbanner::after {
  content: "";
  position: absolute;
  bottom: 1px;
  right: -6px;
  height: 160px;
  width: 213px;
  background: url(../../../assets/right-job-head.svg) no-repeat left bottom;
  background-size: contain;
}
.filterblock {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  z-index: 1;
}
.filterform {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 2rem;
}
.filtercand {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 8px;
  padding: 10px 50px;
  margin-top: 2rem;
}
.jobcard {
  border-radius: 8px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  background: #f8faff;
  max-height: 28rem;
  min-height: 22rem;
}
.jobcard:hover {
  border-color: #b4c0e0;
  background-color: #ffffff;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.cardimggrid {
  padding: 30px 20px 15px 20px;
  display: flex;
  position: relative;
}
.flash {
  position: absolute;
  top: 15px;
  right: 20px;
  height: 25px;
  width: 25px;
  font-size: 12px;
  background: url(../../../assets/flash.svg) no-repeat 0px 0px;
}
.imagebox img {
  min-width: 45px;
  padding-right: 15px;
  height: 45px;
  border-radius: 5px;
}
.rightinfo a {
  font-size: 14px;
  line-height: 26px;
  color: #05264e;
  display: block;
  text-transform: capitalize;
}
.rightinfo a:hover {
  color: #3c65f5;
}
.rightinfo span {
  display: inline-block;
  font-size: 12px;
  color: #a0abb8;
}
.cardimggriddet {
  padding: 0px 20px 30px 20px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.cardimggriddet h6 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #05264e;
  text-decoration: none;
}
.textsmall {
  font-size: 14px;
  color: #4f5e64;
  font-weight: 500;
}
.boxfilters {
  margin-top: 15px;
}

.filterjobs {
  margin-bottom: 0px;
  border-bottom: 1px solid #b4c0e0;
  padding: 10px 0px;
  margin-top: 2px;
}
.headborder {
  border-bottom: 1px solid #b4c0e0;
  padding-bottom: 10px;
}
.jobicons span {
  font-size: 12px;
  color: #a0abb8;
  display: inline-block;
}
.description {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px !important;
  color: #4f5e64;
  /* margin-top: 15px; */
}

.skillreq a {
  text-decoration: none;
  margin-right: 5px !important;
  background-color: #eff3fc;
  font-size: 12px;
  padding: 7px 10px;
  border-radius: 5px;
  color: #4f5e64 !important;
  transition: 0.2s;
  word-break: break-word;
  white-space: normal;
}
.skillreq a:hover {
  color: #3c65f5;
}

/* candidates */

.applybtncard {
  background-color: #e0e6f7;
  color: #3c65f5;
  padding: 15px 2px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
  transition: 0.2s;
  cursor: pointer;
  font-weight: 500;
}
.applybtncard:hover {
  background-color: #3c65f5;
  color: #e0e6f7;
}

.salprice {
  color: #3c65f5;
  font-weight: bold;
  font-size: 18px;
}

.cardgrid {
  padding: 30px 20px 15px 20px;
  display: flex;
  position: relative;
}
.activejob {
  position: absolute;
  width: 100%;
  text-align: end;
  padding: 10px 20px 15px 20px;
}
.activetime {
  position: absolute;
  text-align: end;
  top: 5px;
  right: 15px;
  height: auto;
  width: 100%;
  font-size: 13px;
}
.imgbox img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #b4c0e0;
}
.imgboxdet img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #b4c0e0;
}
.rightinfo {
  font-size: 15px;
  line-height: 1.5;
  font-weight: bold;
  display: block;
}
.rightinfodet {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  display: block;
}
.cardblock {
  padding: 0px 20px 10px 20px;
  position: relative;
}
.cardblockdet {
  padding: 10px 20px;
  position: relative;
}
.icons {
  margin-bottom: 5px;
  margin-right: 2px;
}

.joboverview {
  border: thin solid #e0e6f7;
  border-radius: 8px;
  padding: 20px 30px 30px 30px;
}
.overicon {
  font-size: 18px;
  color: #a0abb8;
  min-width: 20px;
}
.overtext {
  font-size: 16px;
  color: #66789c;
  line-height: 24px;
  font-weight: 400;
}
.smallheading {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.sidebartextinfo {
  word-break: break-word;
  word-break: break-all;
}
.fadein {
  opacity: 0;
  animation: fadeInAnimation 2.1s ease forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sectionbox {
  background: url(../../../assets/bg-box.png) no-repeat top center;
  padding: 57px 0px 96px 0px;
  background-size: cover;
  border-radius: 16px;
  animation: fadeInUp 2s ease forwards;
}

.sidebarfilter {
  padding: 0px 20px 0px 0px;
  border: 0px;
  border-radius: 0px;
  background-color: #ffffff;
}

/* jobdetails */
.bannerimage {
  padding: 20px 0px 20px 0px;
  position: relative;
  max-width: 1770px;
  margin: 0 auto;
}
.bannerimage img {
  width: 100%;
  border-radius: 16px;
}
.joboverview {
  border: thin solid #e0e6f7;
  border-radius: 8px;
  padding: 20px 30px 30px 30px;
  margin-bottom: 50px;
}
.cardimggrid h5,
.joboverview h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #05264e;
}

/* .sidebariconitem {
  font-size: 18px;
  color: #a0abb8;
  min-width: 20px;
} */
.textdes {
  font-size: 16px;
  color: #66789c;
  line-height: 24px;
  font-weight: 400;
  display: inline-block;
  width: 50%;
  min-width: 120px;
}
.smallheading {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.sidebariconitem {
  font-size: 14px;
  margin-bottom: 3px;
}

.sidebarborder {
  border: 1px solid #e0e6f7;
  padding: 0px 25px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}

.sidebarborder a {
  text-decoration: none;
}
.sidebarborder figure {
  float: left;
}
.sidebarborder figure img {
  width: 85px;
  height: 85px;
  border-radius: 16px;
}
.sidebarinfo {
  display: block;
  padding-left: 100px;
  position: relative;
}

.sidebarcompany {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  display: block;
  padding-top: 5px;
  text-decoration: none;
  cursor: pointer;
}

.companyloc {
  font-size: 12px;
  color: #a0abb8;
  display: inline-block;
  line-height: 24px;
}
.linkunderline {
  font-size: 12px;
  line-height: 18px;
  color: #05264e;
  font-weight: 400;
  text-decoration: underline;
  display: block;
}

.boxmap {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}
.boxmap img {
  display: inline-block;
  width: 100%;
  height: 150px;
  cursor: pointer;
  margin-bottom: 15px;
}
.uldisc {
  list-style-type: disc;
  padding-left: 25px !important;
}
.uldisc li {
  list-style-type: disc;
  display: list-item !important;
  padding-bottom: 5px !important;
  color: #4f5e64;
  font-size: 14px;
  line-height: 22px;
}

.sidebarborder h6 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}
.sidebarlistjob {
  display: inline-block;
  width: 100%;
  padding: 20px 0px 0px 0px;
}
.sidebarlistjob ul {
  padding: 0px;
  list-style: none;
}
.sidebarlistjob ul li {
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
}
.cardlist4 {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid #e0e6f7;
}
.hover-up {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.cardlist4 .image img {
  min-width: 60px;
  height: 60px;

  border-radius: 10px;
}
.cardlist4 .infotext {
  width: 100%;
  margin-top: -4px;
}
.infotext h5 {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  color: #05264e;
}
/* .infotext h5:hover {
  color: #3c65f5;
} */
/* .infotext .htext{
  font-size: 16px;
  line-height: 24px;
} */
.cardbriefcase {
  font-size: 12px;
  color: #a0abb8;
  padding: 0px 15px 0px 0px;
}
.cardtime {
  font-size: 12px;
  color: #a0abb8;
}
.cardlist4 .cardprice {
  font-size: 14px;
  color: #3c65f5;
  font-weight: 500;
  font-style: italic;
}
.cardlist4 .cardprice span {
  font-size: 12px;
  line-height: 12px;
  color: #4f5e64;
  font-weight: 500;
}

.sidebarborder .h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  color: #05264e;
}
.contentsingle h4 {
  margin-top: 20px;
  color: #4f5e64;
  font-weight: bold;
  margin-bottom: 20px;
}
.contentsingle p {
  font-size: 16px;
  line-height: 200%;
  color: #4f5e64;
  line-height: 32px;
  margin-bottom: 20px;
}
.authorsingle {
  display: inline-block;
  padding-top: 10px;
  font-size: 18px;
  color: #4f5e64;
  line-height: 28px;
  font-weight: bold;
  margin-top: 2rem;
}
.applyjobs {
  display: inline-block;
  width: 100%;
  padding: 30px 0px 30px 0px;
  border-top: 1px solid rgba(6, 18, 36, 0.1);
  margin-top: 50px;
}

.skillbg {
  font-size: 12px;
  padding: 7px 10px;
  border-radius: 5px;
  line-height: 1;
  background-color: #d8f1ff;
}

.filtertabs {
  background-color: rgb(255, 255, 255);
  color: #e0e6f7;
  background-color: #05264ee7;
  padding: 15px 10px;
  font-weight: 700;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px 0px 0px 0px;
}

.filterseltabs {
  padding: 15px 10px;
  background-color: #e0e6f7;
  font-size: 14px;
  font-weight: 600;
  color: #05264e;
  cursor: pointer;
  border: 1px solid #e0e6f7;
  border-radius: 0px 0px 0px 5px;
}
.tabsem,
.tabsex {
  position: relative;
  overflow: hidden;
  padding: 14px 30px;
  border: 1px solid #e0e6f7;
  color: #05264e;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.3s ease;
  margin-bottom: 1rem;
}

.tabsem::before,
.tabsex::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8faff;
  z-index: -1;
  transition: transform 0.3s ease;
}

.tabsem::before {
  transform: scaleX(0);
  transform-origin: left;
}

.tabsex::before {
  transform: scaleX(0);
  transform-origin: right;
}

.tabsem.active::before {
  transform: scaleX(1);
  transform-origin: right;
}

.tabsex.active::before {
  transform: scaleX(1);
  transform-origin: left;
}

.tabsex {
  border-radius: 0px 5px 0px 0px;
}

.tabsem {
  border-radius: 5px 0px 0px 0px;
}

.tabsem:hover,
.tabsex:hover {
  color: #3c65f5;
}

.tabsem.active,
.tabsex.active {
  color: #3c65f5;
  font-weight: bold;
}

/* swiper */

.boxswiper {
  position: relative;
}

.swiperbuttons {
  position: absolute;
  top: -65px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.swiperbuttons .btns {
  border: none;
  background-color: #e6ecff;
  color: #4f5e64;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.swiperbuttons .btns:hover {
  color: #3c65f5;
}
