/* .switch {
    position: relative;
    display: inline-block;
    width: 88px;
    height: 28px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}



input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(60px);
}


.slider:after {
    content: var(--toggle-text, 'CLOSE');
    
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
}

input:checked+.slider:after {
    content: var(--toggle-text-checked, 'OPEN');
}
 */


.switch-holder {
    display: flex;
    padding: 10px 10px;
    border-radius: 10px;
    /* box-shadow: -8px -8px 15px rgba(255, 255, 255, .7),
        10px 10px 10px rgba(0, 0, 0, .2),
        inset 8px 8px 15px rgba(255, 255, 255, .7),
        inset 10px 10px 10px rgba(0, 0, 0, .2); */
    box-shadow: rgba(149, 157, 165, 0.2) 1px 8px 24px 2px;
    justify-content: space-between;
    align-items: center;
}

.switch-label {
    padding: 0 15px 0 5px
}

.switch-label span {
    font-weight: 600;
    color: #66789C;
}


.switch-toggle {
    height: 40px;
}

.switch-toggle input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -2;
}

.switch-toggle input[type="checkbox"]+label {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 38px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
    box-shadow: inset -2px -2px 15px rgba(255, 255, 255, .6),
        inset 8px 8px 8px rgba(0, 0, 0, .25);
}

.switch-toggle input[type="checkbox"]+label::before {
    position: absolute;
    content: 'CLOSED';
    font-size: 10px;
    text-align: center;
    line-height: 25px;
    top: 8px;
    left: 8px;
    width: 50px;
    height: 25px;
    border-radius: 20px;
    background-color: #e41414;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, .5),
        3px 3px 5px rgba(0, 0, 0, .25);
    color: white;
    font-weight: 600;
    transition: .5s ease-in-out;
}

.switch-toggle input[type="checkbox"]:checked+label::before {
    left: 40%;
    content: 'OPEN';
    font-size: 10px;
    color: #fff;
    background-color: #00b33c;
    font-weight: 600;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, .5),
        3px 3px 5px #00b33c;
}