/* 
.wrapper{
    width:200px;
    height:60px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}
.circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    background-color: #04364A;
    left:15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
}
.circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}


@keyframes fadeIn {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
} */

.ocrloader {
  width: 94px;
  height: 77px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 20px;
    background-color: rgba(45, 183, 183, 0.54);
    z-index: 1;
    transform: translateY(135%);
    animation: move 1.3s cubic-bezier(0.15, 0.44, 0.76, 0.64);
    animation-iteration-count: infinite;
  }
  > div {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48%;
    backface-visibility: hidden;
  }
  i {
    display: block;
    height: 1px;
    background: #000;
    margin: 0 auto 2px;
    margin: 0 auto 2.2px;
    backface-visibility: hidden;
    &:nth-child(2) {
      width: 75%;
    }
    &:nth-child(3) {
      width: 81%;
    }
    &:nth-child(4) {
      width: 87%;
    }
    &:nth-child(6) {
      width: 71%;
    }
    &:nth-child(7) {
      width: 81%;
    }
    &:nth-child(8) {
      width: 65%;
    }
    &:nth-child(9) {
      width: 83%;
    }
    &:nth-child(10) {
      width: 75%;
    }
    &:nth-child(12) {
      width: 86%;
    }
    &:nth-child(14) {
      width: 65%;
    }
    &:nth-child(16) {
      width: 75%;
    }
    &:nth-child(18) {
      width: 83%;
    }
  }
  &:before,
  &:after,
  em:after,
  em:before {
    border-color: #000;
    content: "";
    position: absolute;
    width: 19px;
    height: 16px;
    border-style: solid;
    border-width: 0px;
  }
  &:before {
    left: 0;
    top: 0;
    border-left-width: 1px;
    border-top-width: 1px;
  }
  &:after {
    right: 0;
    top: 0;
    border-right-width: 1px;
    border-top-width: 1px;
  }
  em:before {
    left: 0;
    bottom: 0;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  em:after {
    right: 0;
    bottom: 0;
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
}

@keyframes move {
  0%,
  100% {
    transform: translateY(135%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(272%);
  }
}
.wrapper {
  margin-top:6rem;
  font-size: 14px;
  color: #04364a;
  animation: fadeIn 5s ease-in-out forwards infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}





.loadingbody {
  height: 100%;
  background-color: #f1f0fd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  align-items: center;
  justify-content: center;
  animation: rotate 2s infinite;
  height: 50px;
  width: 50px;
}

.loading:before,
.loading:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loading:before {
  animation: ball1 1s infinite;
  background-color: #7fffd478;
  box-shadow: 30px 0 0 #04364a;
  margin-bottom: 10px;
}
.loading:after {
  animation: ball2 1s infinite;
  background-color: #04364a;
  box-shadow: 30px 0 0 #7fffd478;
}
.loadingtext {
  font-weight: 500;
  color: #04364a;
  opacity: 0;
  animation: text-fade-in-out 2s infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes text-fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #04364a;
  }
  50% {
    box-shadow: 0 0 0 #04364a;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #04364a;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #7fffd478;
  }
  50% {
    box-shadow: 0 0 0 #7fffd478;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #7fffd478;
    margin-top: 0;
  }
}
