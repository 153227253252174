/* header */
.stickybar {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid #e0e6f6;
  background: #fff;
}


/* intro section */
.section {
  padding: 5rem 0rem 2rem 0rem;
  height: auto;
}
.sections {
  padding-bottom: 3rem;
}
.sectionheading{
  font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.25;
}
.mainheading {
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: 0.8px;
  animation: fadeInUp 1s linear alternate;
}
.subheading {
  font-size: 20px;
}
.mainnormaltext {
  font-size: 18px;
  max-width: 600px;
  animation: fadeInUp 1s linear alternate;
}
.homeBtn {
  animation: fadeInUp 1s linear alternate;
}
.normaltext {
  font-size: 18px;
}
.mdtext {
  font-size: 16px;
}
.smtext {
  font-size: 14px;
}
.xstext {
  font-size: 12px;
}
.textbg {
  position: relative;
}
.textbg::after {
  content: "";
  background-color: #3c65f5;
  height: 25px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -6px;
  z-index: -1;
  opacity: 0.1;
}

.bghomepage {
  background-image: url(../../assets/bg-banner.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
  min-height: 750px;
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  right: 0;
}

.bghomepageresume {
  background-image: url(../../assets/bg-banner.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
  min-height: 850px;
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  right: 0;
}

.filterblock {
  position: relative;
  max-width: 800px;
  z-index: 1;
}
.bannerimages {
  position: relative;
  min-height: 640px;
}
.shape1 {
  position: absolute;
  top: -5%;
  left: -15px;
  animation: hero-thumb-animation 2s linear infinite alternate;
}
.shape1 img{
width: 90%;
}.shape2 img{
  width: 90%;
  }
.shape2 {
  position: absolute;
  bottom: 34%;
  right: -80px;
  animation: hero-thumb-sm-animation 4s linear infinite alternate;
}

.shape3 {
  position: absolute;
  top: 5%;
  right: -25px;
  animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}
.shape4 {
  position: absolute;
  bottom: 40%;
  left: 40px;
  animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}
@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-sm-animation {
  0% {
    transform: translateY(-20px) translateX(50px);
  }
  100% {
    transform: translateY(-20px) translateX(0px);
  }
}
@keyframes hero-thumb-sm-2-animation {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* toolsection */
.tools {
  animation: fadeIn 1s ease forwards;
}

.toolcard {
  display: flex;
  padding: 1.5rem 1.2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  transition: transform 0.5s ease;
  animation: fadeInUp 2s ease forwards;
}
.toolcard:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transform: translateY(-10px);
  border-radius: 5px;
  background-color: #f2f6fd;
}
.toolicon img {
  width: 40px;
}

/* boxsection */

.empsectionbox {
  background: url(../../assets/bg-box.png) no-repeat top center;
  padding: 57px 0px 96px 0px;
  background-size: cover;
  border-radius: 16px;
  animation: fadeInUp 2s ease forwards;
}

/* footer */
.footersection {
  background-color: white;
}
.footersection a {
  text-decoration: none;
}
.footercontent {
  border-bottom: 1px solid #dde3e8;
}
.socialicon a {
  background-color: #3c65f5;
  display: inline-block;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  transition: transform 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.socialicon a:hover {
  transform: translateY(-6px);
}
.footerwidget ul,
.footerwidget li {
  list-style-type: none;
  padding-left: 3px;
  font-size: 14px;
  margin-bottom: 8px;
  color: #4f5e64;
}
.footerwidget h3 {
  font-weight: 700;
  font-size: 16px;
  margin: 1.2rem 0rem;
}
.footerwidget a {
  color: #66789c;
  transition: padding-left 0.3s ease, text-indent 0.3s ease;
  cursor: pointer;
}

.footerwidget a:hover {
  color: #05264e;
  padding-left: 5px;
}
.copyrightarea {
  font-size: 12px;
  font-weight: 500;
  padding: 1.2rem 0rem;
}
.footerlogo {
  padding-top: 1.2rem;
}
.copyrightarea ul,
.copyrightarea li {
  display: inline-block;
  list-style-type: none;
  padding-left: 0px;
  margin: 5px 0px;
}
.footermenu ul li a {
  font-size: 12px;
  font-weight: 500;
  color: #4f5e64;
}

.footermenu a:hover {
  color: #3c65f5;
}

/* scroll */
.scrollUp {
  width: 48px;
  height: 48px;
  color: #ffffff;
  right: 30px;
  bottom: 30px;
  border-radius: 30px;
  text-align: center;
  overflow: hidden;
  z-index: 999 !important;
  border: 0;
  background-color: #fff;
  transition-duration: 0.2s;
  background-color: #3c65f5;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
}

/* authimage */

.imgauth {
  position: absolute;
  right: 10%;
  top: 20%;
  width: unset;
  animation: hero-thumb-sm-2-animation 3s linear infinite alternate;
}

.input {
  background-color: white;
  height: 53px;
  border-radius: 4px;
  border: 1px solid #e0e6f6;
  width: 100%;
  line-height: 53px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.togglebtninput {
  position: relative;
  background-color: white;
  height: 53px;
  border-radius: 4px;
  border: 1px solid #e0e6f6;
  width: 100%;
  line-height: 53px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  overflow: hidden;
}

.toggleOption {
  flex: 1;
  text-align: center;
  z-index: 1;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlight {
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #3c65f5;
  top: 0;
  left: 0;
  border-radius: 4px;
  transition: left 0.3s ease;
}

.hirer {
  left: 0;
}

.candidate {
  left: 50%;
}

.active {
  color: white;
}

.authformsection {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate {
  opacity: 0;
  transform: translateY(-10px);
}

/* boxlayout */

.boxletter {
  padding: 57px 0px 96px 0px;
  background: url(../../assets/bg-newsletter.svg) no-repeat 0px 0px;
  background-size: cover;
  border-radius: 16px;
}

.boxtext {
  color: #ffffff;
  font-size: 37px;
  line-height: 55px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: bold;
}
.boxform {
  background: #ffffff;
  max-width: 610px;
  padding: 10px;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
}
.boxform form {
  display: flex;
}
.boxform input {
  width: 90%;
  padding: 15px;
  border: 0px;
  min-height: 60px;
  background-repeat: no-repeat;
  background-position: left 17px center;
  background-image: url(../../assets/emailiconbox.svg);
  padding-left: 60px;
  border-radius: 4px;
  height: 50px;
  box-shadow: none;
  font-size: 14px;
}

.boxform input::placeholder{
  font-size: 14px;
  font-weight: 500;
  color:#DEEBFF;
}