.resumescoretemplate {
  background-color: rgba(0, 0, 0, 0.51);
  width: 100%;
  min-height: 100vh;
  top: -1rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreim {
  width: 280px;
}

.suggestionbody {
  padding: 0rem 2rem;
  max-height: 60vh;
}
.suggestionsection {
  max-height: 40vh;
  overflow-y: auto;
}
.scoreBtn {
  width: fit-content;
  margin-top: 1rem;
  background-color: #04364a;
  border: none;
  font-weight: 600;
  color: #f9f9f9;
  box-shadow: #04364a;
  font-size: 16px;
  cursor: pointer;
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid #04364a;
}
.scoreBtn:hover {
  background-color: #074c68;
  border: 1px solid #074c68;
}
.scoreBtn:disabled {
  cursor: not-allowed;
  background-color: #074c68;
  border: 1px solid #074c68;
}
.scoreprogress{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  min-height:85vh ;
}
.textcenter {
  position: relative;
}

.docScanner {
  color: rgba(224, 228, 230, 0.782);
  animation: fadeInOut 2s ease infinite;
  z-index: 1;
}

.doctext {
  color: #04364a;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 2;
  padding: 0rem 2rem;
  text-align: center;
}

.docScannererr {
  font-size: 12rem;
  color: rgba(255, 17, 0, 0.233);
  animation: fadeInOut 2s ease infinite;
  z-index: 1;
}

.doctexterr {
  color: #ff1010;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 2;
  padding: 0rem 2rem;
  text-align: center;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .scorecalc {
    padding: 2rem;
    background-color: white;
    width: 24rem;
  }
  .scoreim {
    width: 250px;
  }
  .docScanner {
    font-size: 10rem;
  }
}

@media only screen and (min-width: 1800px) {
  .circular {
    width: 250px;
  }
}

.circular {
  width: 150px;
}

.suggestions {
  background-color: rgba(240, 248, 255, 0.788);
  border-left: 4px solid #0d6efd;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.resumescore {
  height: 90vh;
}
