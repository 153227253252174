$primary : #3C65F5;
// $primary : #5891ff;
$dark : #05264E;
$secondary:#66789C;
$light:#A0ABB8;
$info:#B1CEFE;
$success:#25C243;
$font-family-sans-serif:'Plus Jakarta Sans', sans-serif;
$input-btn-font-size : 14px;
$input-btn-padding-y: .7rem;
$input-btn-padding-x:1rem ;
$input-btn-font-size-lg: 15px;
$input-btn-padding-y-lg:.8rem ;
$input-btn-padding-x-lg:1.5rem ;
$offcanvas-horizontal-width:600px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 968px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1800px
) !default;

$container-max-widths: (
  sm: 570px,
  md: 800px,
  lg: 1100px,
  xl: 1300px,
  xxl: 1400px
) !default;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1320px
// ) !default;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// ) !default;
@import '../node_modules/bootstrap/scss/bootstrap'