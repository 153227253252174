.CompanyProfile {
  max-width: 90%;
  display: table;
  table-layout: fixed;
  margin: auto;
  width: 100%;
  padding: 20px 0px 0px 0px;
  position: relative;
}

.imageCompany {
  position: absolute;
  top: -50px;
  left: 0px;
}
.aboutus h2 {
  color: #4f5e64;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 24px;
}

.aboutus h4 {
  margin-bottom: 20px;
  font-size: 20px;
}

.boxmap img {
  display: inline-block;
  width: 100%;
  height: 150px;
  cursor: pointer;
  margin-bottom: 15px;
}
.sidebartextinfo {
  word-break: break-word;
  word-break: break-all;
  padding-bottom: 1rem;
}

.textdescription {
  font-size: 14px;
  color: #66789c;
  line-height: 22px;  

}
.smallheading {
  color: #05264e;
  font-size: 14px;
}

.aboutus p {
  font-size: 16px;
  line-height: 200%;
  color: #4f5e64;
  line-height: 32px;
  margin-bottom: 20px;
}

.imageCompany img {
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 13px;
  background-color: white;
  padding: 5px;
}

.description {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px !important;
  color: #4f5e64;
}
.companyName {
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.companyLink {
  font-size: 22px;
  font-weight: 600;
  text-decoration: underline;
}

.companyLocation {
  font-size: 13px;
  color: #a0abb8;
  display: inline-block;
  padding: 0px 0px 0px 20px;
  line-height: 24px;
}

.editprofile:hover {
  background-color: #2f5af6;
}

.contactUs:hover {
  background-color: #2f5af6;
}

.contactUs span {
  font-size: 16px;
}

.customNavlink {
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  gap: 6px;
  padding: 8px 12px;
  transition: all 0.3s;
}


.customNavlink:hover {
  transform: translateY(-3px);
}

.smText {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4f5e64;
  gap: 2px;
}

.mdText {
  font-size: 15px;
  color: #4f5e64;
}
.lighttxt {
  color: #a0abb8;
  font-size: 14px;
  line-height: 24px;
}
.jobCard {
  background-color: #f8faff;
  border: 1px solid #e0e6f7;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.jobCard:hover {
  background-color: #ffffff;
  border-color: #b4c0e0;
  transform: translateY(-4px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.skillBadge {
  background-color: #eff3fc;
  border-radius: 5px;
  display: inline-block;
  font-size: 15px;
  color: #4f5e64;
}

.jobCard:hover .openVacancy {
  background-color: #e2e8f5;
  color: #2f5af6;
}
.jobCard .closedVacancy {
  background-color: #c31212;
  color: #fff;
}
.jobCard:hover .closedVacancy {
  background-color: #e2e8f5;
  color: #c31212;
}

.applyNow {
  border: none;
  border-radius: 5px;
  padding: 7px;
  color: #2f5af6;
  background-color: #e2e8f5;
  font-size: 15px;
}

.openVacancy {
  background-color: #2f5af6;
  color: #fff;
}

.closedVacancy {
  background-color: #e2e8f5;
  color: #2f5af6;
}

.iconStyles {
  font-size: 20px;
}

.spinAnimation {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* plancard */


.plancard {
  border-radius: 8px;
  border: 1px solid #e0e6f7;
  overflow: hidden;
  position: relative;
  background: #ffff;
  height: 22rem;
  padding: 20px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}
.plancardsel {
  border: 1px solid #3C65F5;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background: #f8faff;
  height: 22rem;
  padding: 20px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}
.plancard:hover {
  /* border-color: #b4c0e0; */
  background-color: #ffffff;
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
