.sectionbox {
  background: url(../../../assets//bg-box.png) no-repeat top center;
  padding: 2rem;
  background-size: cover;
  border-radius: 16px;
  min-height: 14rem;
}

.fileupload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.fileupload .fileselect {
  display: block;
  border: 4px dashed #3c64f57c;
  border-radius: 25px;
  color: #34495e;
  cursor: pointer;
  /* line-height: 40px; */
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  height: 25rem;
}

.fileupload .fileselect:hover {
  border-color: #3c65f5;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.fileupload .fileselect input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.fileupload .fileselect.fileselect-disabled {
  opacity: 0.65;
}

.fileupload .fileselect.fileselect-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #3c65f5;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.resumelist {
  padding: 1rem 2rem;
}
.underline{
  padding: 3px 0px;
  border-bottom: 1px solid  #05264E;
}
.numberelement {
  color: #3c65f5;
  background-color: #B1CEFE;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  margin: 0rem 1rem;
  text-align: center;
}

.numlist {
  background-color: #3c65f5;
  /* padding: 5px 10px; */
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numlisttxt {
  font-size: 16px;
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0000, #000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after {
  background: radial-gradient(closest-side at 50% 12.5%, #000 96%, #0000) 50% 0/20%
      80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%, #000 96%, #0000) 0 50%/80% 20%
      repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {
    transform: rotate(1turn);
  }
}

.sidebarresume {
  background: #f2f6fd url(../../../assets/ResumeList.svg) no-repeat bottom left;
  background: #f2f6fd url(../../../assets/ResumeList.svg) no-repeat bottom right;
  padding: 10px 40px 560px 0px;
  border-radius: 4px;
  margin-bottom: 40px;
}

.sidebarresume .span1 {
  color: #b4c0e0;
  font-size: 24px;
  line-height: 37px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
}
.sidebarresume .span2 {
  color: #66789c;
  font-size: 36px;
  font-weight: 800;
  line-height: 57px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: -15px;
}

.group {
  padding: 0.7rem 0rem;
  display: flex;
  position: relative;
}

.imgcontainer {
  position: relative;
  width: 200px;
  height: 270px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.imgcontainer img {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.imageoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imgcontainer:hover img {
  opacity: 0.7;
}

.imgcontainer:hover .imageoverlay {
  opacity: 1;
}

.expandicon {
  font-size: 2rem;
  color: #333;
}


.fixedbtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}