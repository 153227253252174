.mdText {
    font-weight: 600;
    font-size: 14px;
}

.smText {
    font-weight: 500;
    font-size: 12px;
}

.ProgressbarDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.badgeDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.pointer {
    cursor: pointer;
}

.centerText {
    display: flex;
    justify-content: center;
    align-items: center;

}

.candiateDetails {
    border: 1px solid #eff0f2;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)
}


.candiateDetails:hover {
    transform: translateY(-4px);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    border: 1px solid #607eec;
}

.card {
    max-width: 1020px;
    border-width: 1px;
    border-color: rgba(219, 234, 254, 1);
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 1);
    padding: 1rem 2rem;
}


.skillreqcand {
    text-decoration: none;
    margin-right: 5px !important;
    background-color: #eff3fc;
    font-size: 12px;
    padding: 7px 10px;
    border-radius: 5px;
    color: #4f5e64 !important;
    transition: 0.2s;
    word-break: break-word; 
    white-space: normal;
  
  }